@import "bootstrap/scss/variables";

select.form-control { -webkit-appearance: menulist; }
select.custom-select { -webkit-appearance: menulist; }

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.float-right {
  float: right !important;
}

.text-right {
  text-align: right !important;
}

.text-md-right {
  text-align: right !important;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row>.col, .form-row>[class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.form-text {
  display: block;
}

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .5rem .375rem .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
