@import "bootstrap/scss/variables";

select.form-control { -webkit-appearance: menulist; }
select.custom-select { -webkit-appearance: menulist; }

h1, h2, h3, h4, h5, h6 {
  font-weight: $font-weight-semibold;
}

.btn {
  i:first-child, [class^="fa"]:first-child {
    margin-right: $spacer * 0.5;
  }
}

.alert {
  i:first-child, [class^="fa"]:first-child {
    margin-right: $spacer * 0.5;
  }
}

.table-sm {
  td {
    vertical-align: middle;
  }
}

.actions-cell {
  // https://stackoverflow.com/a/43615091
  width: 0.1%;
  white-space: nowrap;
  .btn:not(:last-child) {
    margin-right: $spacer * 0.25;
  }
}

/* make these go over the top of sticky table headers which are 1020 */
.dropdown-menu {
  z-index: 2000;
}

.text-right {
  text-align: right;
}

